import React, { useEffect } from "react";
import GLightbox from "glightbox";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { Grid, ImageList } from '@mui/material';
import { ImageListItem } from '@mui/material';

const photos = [
    "https://pavefoundation.s3.amazonaws.com/ssdg_0.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_1.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_2.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_3.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_4.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_5.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_6.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_7.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_8.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_9.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_10.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_11.jpg",
    "https://pavefoundation.s3.amazonaws.com/ssdg_12.jpg",
]
const renderPhotos = (photos) => {
    return photos.map(url => {
        return (
            <div className="col-lg-4 col-sm-6">
                <div className="gallery-item">
                    <img src={url} alt="" />
                    <a href={url} className="glightbox">
                        <span className="gallery-icon"></span>
                    </a>
                </div>
            </div>
        )
    })
}

const GalleryMix = () => {
    useEffect(() => {
        // eslint-disable-next-line no-unused-vars
        const lightbox = GLightbox({
            touchNavigation: true,
            loop: true,
            autoplayVideos: true,
            beforeSlideLoad: (slide, data) => {
                // Need to execute a script in the slide?
                // You can do that here...
            },
        });
    });
    return (
        <section className="gallery-area2">
            {/* How can I make the container a bit wider? */}
            <div className="container">
                <Grid
                    alignItems="center"
                    alignContent="center"
                    columns={1}
                    
                >
                    <Accordion sx={{
                        width: "auto",
                        margin: "auto"
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography

                                sx={{
                                    'typography': {
                                        fontFamily: 'Poppins',
                                        fontWeight: 'bold',
                                        color: '#424242',
                                        fontSize: 50
                                    }
                                }}>Super Science Day of the Girl 2022</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                        >

                            <Box
                                margin={"auto"}
                                sx={{
                                    width: 3 / 4,
                                    height: 1 / 4,
                                    overflowY: 'scroll',
                                }}>
                                <ImageList variant="masonry" cols={3} gap={16}>
                                    {photos.map((photo) => (
                                        <ImageListItem>
                                            <div className="gallery-item">
                                                <img
                                                    src={`${photo}?w=248&fit=crop&auto=format`}
                                                    srcSet={`${photo}?w=248&fit=crop&auto=format&dpr=2 2x`}

                                                    loading="lazy"
                                                />
                                                <a href={photo} className="glightbox">
                                                    <span className="gallery-icon"></span>
                                                </a>
                                            </div>
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>


                            {/* <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography> */}
                        </AccordionDetails>
                    </Accordion>
                    {/* 2022 */}
                    <div class="section-heading">
                        <div class="section-icon">
                            <img src="/images/section-icon.png" alt="section-icon" />
                        </div>
                        <h2 class="section__title h4">Summer Camp 2022</h2>
                    </div>

                    <div className="row">

                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b1.jpg" alt="" />
                                <a href="/images/img_b1.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b2.jpg" alt="" />
                                <a href="/images/img_b2.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b3.jpg" alt="" />
                                <a href="/images/img_b3.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b4.jpg" alt="" />
                                <a href="/images/img_b4.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b5.jpg" alt="" />
                                <a href="/images/img_b5.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b6.jpg" alt="" />
                                <a href="/images/img_b6.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b7.jpg" alt="" />
                                <a href="/images/img_b7.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b8.jpg" alt="" />
                                <a href="/images/img_b8.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b9.jpg" alt="" />
                                <a href="/images/img_b9.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b10.jpg" alt="" />
                                <a href="/images/img_b10.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b11.jpg" alt="" />
                                <a href="/images/img_b11.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/img_b12.jpg" alt="" />
                                <a href="/images/img_b12.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* 2021 Summer Camp */}
                    <div className="row">
                        <div class="section-heading">
                            <div class="section-icon">
                                <img src="/images/section-icon.png" alt="section-icon" />
                            </div>
                            <h2 class="section__title h4">Super Science Day of the Girl 2021</h2>
                        </div>
                    </div>
                    <div className='row'>
                        {renderPhotos(photos)}
                    </div>
                    {/* 2020 */}
                    <div className="row">
                        <div class="section-heading">
                            <div class="section-icon">
                                <img src="/images/section-icon.png" alt="section-icon" />
                            </div>
                            <h2 class="section__title h4">Back to School 2020</h2>
                        </div>
                    </div>
                    <div className="row">
                        {/* END */}
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img13.jpg" alt="" />
                                <a href="/images/gallery-img13.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img14.jpg" alt="" />
                                <a href="/images/gallery-img14.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img15.jpg" alt="" />
                                <a href="/images/gallery-img15.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img16.jpg" alt="" />
                                <a href="/images/gallery-img16.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>



                    </div>
                    {/* 2019 */}
                    <div className="row">
                        <div class="section-heading">
                            <div class="section-icon">
                                <img src="/images/section-icon.png" alt="section-icon" />
                            </div>
                            <h2 class="section__title h4">Summer Camp 2019</h2>
                        </div>
                    </div>
                    <div className="row">
                        <br />
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img4.jpg" alt="" />
                                <a href="/images/gallery-img4.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img5.jpg" alt="" />
                                <a href="/images/gallery-img5.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img6.jpg" alt="" />
                                <a href="/images/gallery-img6.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img7.jpg" alt="" />
                                <a href="/images/gallery-img7.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img8.jpg" alt="" />
                                <a href="/images/gallery-img8.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img9.jpg" alt="" />
                                <a href="/images/gallery-img9.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img10.jpg" alt="" />
                                <a href="/images/gallery-img10.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img11.jpg" alt="" />
                                <a href="/images/gallery-img11.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="gallery-item">
                                <img src="/images/gallery-img12.jpg" alt="" />
                                <a href="/images/gallery-img12.jpg" className="glightbox">
                                    <span className="gallery-icon"></span>
                                </a>
                            </div>
                        </div>

                    </div>
                </Grid>
            </div>
        </section>
    );
};

export default GalleryMix;
