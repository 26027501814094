import React from "react";
import DonorBox from "../../donations/DonorBox";

export default function DonateForm() {
  return (
    <div>
      <h2
        className="section__title text__white"
        style={{
          textAlign: "center",
          marginBottom: "15px",
          color: "#343434",
        }}
      >
        Your Donation Helps!
      </h2>
      <p
        className="section__desc"
        style={{
          textAlign: "center",
          marginBottom: "10px",
          color: "#343434",
        }}
      >
        Credit, Debit, PayPal, and ACH (Bank) Payments Accepted!
      </p>

      <DonorBox />
    </div>
  );
}
