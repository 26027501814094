import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { Grid, ImageList } from "@mui/material";
import { ImageListItem } from "@mui/material";
import CMSService from "../../services/cms.js";

export default function GallerySandbox() {
  //   React.useState(opened);
  const [galleries, setGalleries] = useState([]);
  React.useEffect(() => {
    console.log("mounted");
    CMSService.getAllGalleries().then((res) => {
      console.log(res);
      setGalleries(res);
    });
    console.log("galleries", galleries);
  }, []);
  const renderGallery = (galleries) => {
    console.log("RENDER GALLERY ", galleries);
    if (galleries.length === 0) {
      console.log("empty gallery");
      return;
    }

    return galleries.map((gallery) => {
      return (
        <Accordion
          sx={{
            width: 4 / 4,
            margin: "auto",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <b>{gallery.name}</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              margin={"auto"}
              sx={{
                width: 3 / 4,
                height: 1 / 4,
                overflowY: "scroll",
              }}
            >
              <ImageList variant="masonry" cols={3} gap={16}>
                {gallery.images.map((item) => (
                  <ImageListItem key={item.asset.url}>
                    <img
                      src={`${item.asset.url}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.asset.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={
                        item.title || `image ${Math.floor(Math.random()) * 10}`
                      }
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  };
  return (
    <Grid alignItems="center" alignContent="center" columns={1}>
      {renderGallery(galleries)}
    </Grid>
  );

  //   return (
  //     <Grid alignItems="center" alignContent="center" columns={1}>
  //       <Accordion
  //         sx={{
  //           width: 4 / 4,
  //           margin: "auto",
  //         }}
  //       >
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel1a-content"
  //           id="panel1a-header"
  //         >
  //           <Typography>Accordion 1</Typography>
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <Box
  //             margin={"auto"}
  //             sx={{
  //               width: 3 / 4,
  //               height: 1 / 4,
  //               overflowY: "scroll",
  //             }}
  //           >
  //             <ImageList variant="masonry" cols={3} gap={16}>
  //               {itemData.map((item) => (
  //                 <ImageListItem key={item.img}>
  //                   <img
  //                     src={`${item.img}?w=248&fit=crop&auto=format`}
  //                     srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
  //                     alt={item.title}
  //                     loading="lazy"
  //                   />
  //                 </ImageListItem>
  //               ))}
  //             </ImageList>
  //           </Box>

  //           {/* <Typography>
  //                         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
  //                         malesuada lacus ex, sit amet blandit leo lobortis eget.
  //                     </Typography> */}
  //         </AccordionDetails>
  //       </Accordion>
  //       <Accordion>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel2a-content"
  //           id="panel2a-header"
  //         >
  //           <Typography>Accordion 2</Typography>
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <Typography>
  //             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
  //             malesuada lacus ex, sit amet blandit leo lobortis eget.
  //           </Typography>
  //         </AccordionDetails>
  //       </Accordion>

  //       <Accordion>
  //         <AccordionSummary
  //           expandIcon={<ExpandMoreIcon />}
  //           aria-controls="panel2a-content"
  //           id="panel2a-header"
  //         >
  //           <Typography>Accordion 2</Typography>
  //         </AccordionSummary>
  //         <AccordionDetails>
  //           <Typography>
  //             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
  //             malesuada lacus ex, sit amet blandit leo lobortis eget.
  //           </Typography>
  //         </AccordionDetails>
  //       </Accordion>
  //     </Grid>
  //   );
}

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
    title: "Bed",
  },
  {
    img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
    title: "Books",
  },
  {
    img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
    title: "Sink",
  },
  {
    img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
    title: "Kitchen",
  },
  {
    img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
    title: "Blinds",
  },
  {
    img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
    title: "Chairs",
  },
  {
    img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
    title: "Laptop",
  },
  {
    img: "https://images.unsplash.com/photo-1481277542470-605612bd2d61",
    title: "Doors",
  },
  {
    img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
    title: "Coffee",
  },
  {
    img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
    title: "Storage",
  },
  {
    img: "https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62",
    title: "Candle",
  },
  {
    img: "https://images.unsplash.com/photo-1519710164239-da123dc03ef4",
    title: "Coffee table",
  },
];
