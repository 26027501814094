import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import CMSService from "../../services/cms";

//commit

const conflict = () => {
  console.log("UPLOAD TEAM MEMBER ");
};

const TeamArea = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchTeam();
  }, []);

  const fetchTeam = async () => {
    const team = await CMSService.getTeam();

    let teamArray = team.map((member) => {
      const { imageUrl, name, background, committee } = member;

      // console.log(background);

      const paragraphs = background.split("\n");
      const generateRandomColorBackground = () => {
        const randomIndex = Math.floor(Math.random() * 4);
        const classes = [
          "team-item-1",
          "team-item2",
          "team-item3",
          "team-item4",
        ];
        console.log(classes[randomIndex]);
        return classes[randomIndex];
      };

      const newTeammate = {
        imgSrc: imageUrl,
        name,
        hasBio: background ? true : false,
        committee,
        paragraphs,
        className: generateRandomColorBackground(),
      };
      return newTeammate;
    });
    setData(teamArray);
  };

  const handleBioClick = (i) => {
    console.log(data[i]);
    setModalData(data[i]);
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    setModalData(null);
  };
  //renderModal
  const renderModal = (open, data) => {
    if (!open) return null;
    const { imgSrc, name, committee, paragraphs } = modalData;
    return (
      <div className="custom-modal-content">
        <img
          src={imgSrc}
          alt="person"
          className="img-fluid custom-modal-image"
        />
        <h3
          className="team__title-title  text-align-center pl-1 pr-2 "
          style={{ color: "#000" }}
        >
          {name}
        </h3>
        <p style={{ fontWeight: 400, fontSize: "24px", padding: "5px" }}>
          {" "}
          Committee: {committee}
        </p>
        <div className="custom-modal-paragraphs">
          {paragraphs.map((par) => (
            <p
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {par}
            </p>
          ))
          // <p className="custom-modal-p">{modalData.paragraph}</p>
          }
        </div>
      </div>
    );
  };
  //TestTeam Render
  return (
    <section className="team-area text-center team-item-2">
      <Modal isOpen={modalOpen}>
        <span
          className="custom-close"
          style={{
            alignSelf: "flex-end",
            margin: "5px",
            width: "10px",
            height: "30px",
            fontSize: "18px",
            cursor: "pointer",
          }}
          onClick={() => handleClose()}
        >
          &times;
        </span>
        {renderModal(modalOpen, modalData)}
      </Modal>
      <div className="container">
        <div className="row mt-3">
          <div className="col-lg-8 mx-auto">
            <div className="section-heading">
              <div className="section-icon">
                <img
                  src="https://du1gs2dc78gvf.cloudfront.net/section-icon.png"
                  alt="section-icon"
                />
              </div>
              <h2 className="section__title h4">Board Members</h2>
            </div>
          </div>
        </div>
        <div className="row team-content-wrap">
          {data.map((el, i) => (
            <div className="col-6 col-md-4 col-lg-3">
              <div
                className={`team-item ${el.className} mb-4`}
                style={{ maxWidth: "200px", margin: "0 auto" }}
              >
                <img src={el.imgSrc} alt="person" className="img-fluid" />
                <div className="team__title d-flex justify-content-center align-items-center">
                  <h3
                    className="team__title-title  text-align-center pl-1 pr-2 "
                    style={{ color: "#fff" }}
                  >
                    {el.name}
                  </h3>
                  {el.hasBio ? (
                    <>
                      <button
                        style={{
                          display: "block",
                          padding: "1px 2px",
                          borderRadius: "5px",
                          marginTop: "20px",
                          fontSize: "12px",
                          width: "60px",
                          fontWeight: "bold",
                          backgroundColor: "white",
                          "@media": {},
                        }}
                        onClick={() => handleBioClick(i)}
                      >
                        Bio
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamArea;

// const [teamMembers, setTeamMembers] = useState([]);

//     useEffect(() => {
//         fetchTeam();
//     }, []);

//     const fetchTeam = async () => {
//         const team = await CMSService.getTeam();
//         console.log(team);
//         setTeamMembers(team)
//     };
