import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../Layout";
// import Donate from "../components/donations/Donate";
import PageHeader from "../PageHeader";
import axios from "axios";
import DonorBox from "../donations/DonorBox";
import DonateForm from "../events/eventForms/DonateForm";


export default function TestEventsDetailPage() {
    const [event, setEvent] = useState();

    let { slug } = useParams();
    const url = window.location.pathname.split('/').pop();

    console.log(slug);
    console.log(url);

    useEffect(() => {
        fetchEvent();
        console.log(event);
    }, [url])

    const fetchEvent = async () => {
        const eventReqRaw = await axios.get(
            `https://fh167gzh.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%3D%3D%22events%22%20%26%26%20slug.current%3D%3D%22${slug}%22%5D%20%7B%0A%20%20...%2C%0A%20%20%20%20%22imageUrl%22%3A%20image.asset-%3Eurl%2C%0A%20%20%20%20%20%20%22slugKey%22%3A%20slug.current%2C%0A%20%20%20%20%20%20%0A%20%20%20%20%20%20%0A%0A%7D%0A`
        );
        const eventReq = await eventReqRaw.data.result[0];
        console.log("Element of event " + eventReq);



        console.log(eventReq.start);
        let startParsed = new Date(eventReq.start);
        console.log("UTC Date Object: " + startParsed)
        startParsed.toLocaleString();

        console.log('Local Version: ' + startParsed);


        let hours = startParsed.getHours();
        hours = (hours % 12) ? (hours % 12) : 12;
        const startTime = `${hours}:${startParsed.getMinutes()}`;


        const detailsArr = eventReq.details.split('\n');
        let eventReqObj = {
            eventName: eventReq.name,
            backgroundColor: eventReq.backgroundColor ? eventReq.backgroundColor.hex : "#26c5c5",
            imgSrc: eventReq.imageUrl,
            title: 'Details',
            details: detailsArr,
            start: startParsed,
            location: eventReq.location,
            contactEmail: eventReq.contactEmail ? eventReq.contactEmail : 'info@thepavefoundation.org',
            formComponent: <DonateForm />,
            blogTag: "",
        }
        console.log("New object" + eventReqObj)
        setEvent(eventReqObj);
    }

    // const { eventName, trackerComponent, backgroundColor, month, day, year, imgSrc, title, details, startingTime, location,
    //     contactEmail, formComponent, blogTag } = event;


    if (!event) {
        return (
            <p>Still loading</p>
        )
    }
    return (
        <Layout>

            <PageHeader title={event.eventName} backgroundColor={event.backgroundColor} />
            <section className="event-detail-area pt-2 pb-2">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-start">
                        <div className="col-lg-8">
                            <div className="blog-content mb-3" style={{ height: "350px" }}>
                                <div className="blog-item h-100">
                                    <div className="blog-img" style={{ height: "350px" }}>
                                        <img
                                            src={event.imgSrc}
                                            alt={event.title}
                                            className="img-fluid"
                                            style={{ height: "350px" }}
                                        />
                                        <span className={`blog__tag blog__tag${event.blogTag}`}
                                            style={{
                                                backgroundColor: event.backgroundColor
                                            }}>
                                            <span className="date__num-text"
                                                style={{
                                                    backgroundColor: event.backgroundColor
                                                }}>{event.start.getDate()}</span>
                                            <span className="date__mon-text"
                                                style={{
                                                    backgroundColor: event.backgroundColor
                                                }}>{event.start.toLocaleString('default', { month: 'long' })}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="event-detail-content col-lg-6">
                            <div className="event-detail-item">
                                <h3 className="event__title">{event.title}</h3>
                                <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                    {event.trackerComponent ? event.trackerComponent : null}
                                </div>
                                {event.details.map((par) => (
                                    <p className="event__text">{par}</p>
                                ))}
                            </div>
                        </div>

                        {event.formComponent}
                    </div>
                </div>
            </section>


        </Layout>
    );
}
