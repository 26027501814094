import React from "react";

export default function Carousel() {
  const data = [
    {
      class: "carousel-item",
      title: "2022 Giving Tuesday Drive",
      body: "Help Fund Our Mission Text DONATE to 408-448-2426",
      button: "Donate",
      imgSrc: "/images/slider2.jpg",
      href: "/events/givingtuesday",
    },
  ];

  return (
    <div
      id="myCarousel"
      className="carousel slide"
      data-ride="carousel"
      data-interval={1000}
      style={{ backgroundColor: '#26c5c5', height: "400px" }}
    >

      <div
        className="carousel-inner "
        style={{ height: '100%' }}
      >

        <div

          style={{ height: "100%" }}
          key={'Donate'}
        >
          <div
            className="container d-flex flex-column justify-content-center align-items-center"
            style={{ height: '100%' }}
          >
            <img
              className=" carousel__img img-responsive bd-placeholder-img d-block "
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              src={"/images/slider3.jpg"}
              alt={'donate'}
              style={{
                height: "100%",
                alignSelf: "center",
                justifySelf: "center",
              }}
            />
            {/* </div>

              <div className="container"> */}
            <div className="carousel-caption mb-2" style={{ width: '500px' }}>
              <h1 className="carousel__title w-100" >2022 Year End Drive</h1>
              <p className="carousel__desc "> Help Fund Our Mission </p>

              <p>
                <a
                  className={"theme-btn"}
                  href={'/events/givingtuesday'}
                  role="button"
                >
                  {'Donate'}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
