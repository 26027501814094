import React, { useState, useEffect } from "react";
import cmsService from '../../services/cms'

const TestEvents = () => {
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, [])

  /**
  * Used in the useEffect hook to fetch and set state for events.
  */
  const fetchEvents = async () => {
    const events = await cmsService.getAllEvents();

    let eventsArray = events.map(event => {

      let date = new Date(event.start);
      date.toLocaleString();
      const monthInd = date.toLocaleString('default', { month: 'long' })
      const yearInd = date.getFullYear();
      const day = date.getDate();

      const eventPreview = {
        imgSrc: event.imageUrl,
        month: `${monthInd} ${yearInd}`,
        day,
        linkHref: `/events/${event.slugKey}`,
        linkTitle: event.name,
        dateDetails: `${monthInd} ${day} ${yearInd}`,
        location: event.location
      }
      console.log("event element " + eventPreview)
      return eventPreview;

    });
    setEventsData(eventsArray);
  }

  return (
    <section className="causes-area upcoming-event-area upcoming-event-area2 pt-2 pb-2">
      <div className="container">
        <div className="row blog-content-wrap">
          {/* EVENTS DATA  */}
          {eventsData.map((el, i) => {
            return (
              <div className="col-lg-4">
                <div className="blog-content">
                  <div className={`blog-item blog-item${i + 1}`}>
                    <div className="blog-img">
                      <img src={el.imgSrc} alt={el.linkTitle} style={{ height: '225px' }} />
                      <span
                        className={`blog__tag blog__tag${i + 1}`}
                        style={{ backgroundColor: el.backgroundColor }}
                      >
                        <span className="date__mon-text">{el.month}</span>
                        <span className="date__num-text">{el.day}</span>
                      </span>
                    </div>
                    <div className="blog-inner-content">
                      <h3 className="blog__title">
                        <a href={el.linkHref}>{el.linkTitle}</a>
                      </h3>
                      <ul className="blog__list">
                        <li className="blog__dot-active"> {el.dateDetails}</li>
                        <li>{el.location}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );


};

export default TestEvents;
