import axios from "axios";
const baseUrl = "https://fh167gzh.api.sanity.io/v2021-10-21/data";

/**
 *
 * @returns array of team members
 */
const getTeam = async () => {
  const response = await axios.get(
    `${baseUrl}/query/production?query=*%5B_type%3D%3D%22team%22%5D%20%7B%20%0A%20%20%22id%22%3A_id%2C%0A%20%20name%2C%0A%20%20%20%20committee%2C%0A%20%20role%2C%0A%20%20background%2C%0A%20%20_updatedAt%2C%0A%20%20_type%2C%0A%20%20_rev%2C%0A%20%20_createdAt%2C%0A%20%20%22imageUrl%22%3A%20image.asset-%3Eurl%0A%7D%0A`
  );
  return response.data.result;
};

/**
 *
 * @returns array of all events
 */
const getAllEvents = async () => {
  const response = await axios.get(
    `${baseUrl}/query/production?query=*%5B_type%3D%3D%22events%22%5D%20%7B%0A%20%20...%2C%0A%20%20%20%20%22imageUrl%22%3A%20image.asset-%3Eurl%2C%0A%20%20%20%20%20%20%22slugKey%22%3A%20slug.current%2C%0A%20%20%20%20%20%20%0A%20%20%20%20%20%20%0A%0A%7D%0A`
  );
  return response.data.result;
};

/**
 *
 * @param {*} param0
 * @returns array of specific event requested by id
 */
const getSpecificEvent = async ({ id }) => {
  const response = await axios.get(
    `${baseUrl}/query/production?query=*%5B_type%3D%3D%22events%22%20%26%26%20_id%3D%3D'${id}'%5D%0A`
  );
  return response.data.result;
};

/**
 * GET request for all galleries
 * @returns all galleries in array of objects
 */
const getAllGalleries = async () => {
  const query = encodeURI(`*[_type == 'gallery']{
    name,
    images[]{
      asset->{url}
    }
  }
  `);
  const response = await axios.get(
    `${baseUrl}/query/production?query=${query}`
  );
  return response.data.result;
};

/**
 * GET request for gallery with specific id
 *
 * @param {id}
 * @returns Gallery object
 */
const getSpecificGallery = async ({ id }) => {
  const response = await axios.get(
    `${baseUrl}/query/production?query=*%5B_type%3D%3D%22gallery%22%20%26%26%20_id%3D%3D'${id}'%5D%0A`
  );
  return response.data.result;
};

export default {
  getTeam,
  getAllEvents,
  getSpecificEvent,
  getAllGalleries,
  getSpecificGallery,
};
