import React from "react";
//react router
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

// pages
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import EventsPage from "./pages/EventsPage";
import EventsDetailPage from "./pages/EventsDetailPage";
import Donate from "./pages/DonatePage";
import GalleryPage from "./pages/GalleryPage";
import ContactPage from "./pages/ContactPage";
import TitlebarImageList from "./components/sandboxes/GallerySandbox";
import TeamArea from "./components/info/TeamArea";
import NavSandbox from "./components/sandboxes/NavSandbox";
import Footer from "./components/Footer";
import TestEventsDetailPage from "./components/sandboxes/TestEventsDetailPage";
import SwipeableTextMobileStepper from "./components/sandboxes/CarouselSandbox";
import GalleryMix from "./components/sandboxes/GallerySandboxMix";


//app file w/ react-router
const history = createBrowserHistory();
function App() {

  return (
    <Router history={history}>
      <NavSandbox />
      <Route exact path="/">
        <HomePage />
      </Route>

      <Route exact path="/events">
        <EventsPage />
      </Route>

      <Route path='/events/:slug'>
        <TestEventsDetailPage />
      </Route>

      <Route path="/donate">
        <Donate />
      </Route>

      <Route path="/explore/gallery">
        <GalleryPage />
      </Route>

      <Route path="/about">
        <AboutPage />
      </Route>

      <Route path="/galleryTest">
        <TitlebarImageList />
      </Route>

      <Route path='/galleryMix'>
        <GalleryMix />
      </Route>

      <Route path="/contact">
        <ContactPage />
      </Route>

      <Route path="/stepper">
        <SwipeableTextMobileStepper />
      </Route>

      <Footer />

    </Router>
  );
}

export default App;