import React from "react";
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Events from "../components/events/Events";
import TestEvents from "../components/sandboxes/TestEvents";

const EventsPage = () => {
  return (
    <Layout pageTitle="Pave Foundation Events">
      <PageHeader title="Events" backgroundColor="#27C2C8" />
      <TestEvents />
      {/* <Events /> */}
    </Layout>
  );
};

export default EventsPage;
