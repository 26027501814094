import React from "react";
const Modal = (props) => {
  if (props.isOpen)
    return (
      <div id="myModal">
        {/* <div
      
          }}
        > */}
        {props.children}
        {/* </div> */}
      </div>
    );
  else {
    return <div></div>;
  }
};
export default Modal;
