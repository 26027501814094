import React from "react";
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import Footer from "../components/Footer";
import ServiceArea from "../components/info/ServiceArea";
import HelpingArea from "../components/donations/HelpingArea";
import TeamArea from "../components/info/TeamArea";
import Carousel from "../components/info/Carousel";
import NavSandbox from "../components/sandboxes/NavSandbox";
import GalleryCarousel from '../components/templates/GalleryCarousel'

const HomePage = () => {
  return (
    <Layout pageTitle="Pave Foundation">
      <Carousel />
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <img style={{ margin: '0-auto' }} src='images/goalMeter.png' />
      </div>

      <ServiceArea />
      <HelpingArea />
      <TeamArea />
    </Layout>
  );
};

export default HomePage;
